import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth";
import cartReducer from "../slices/cart";
import wishlistReducer from "../slices/wishlist";
import filterReducer from "../slices/filter";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    filter: filterReducer,
  },
});
