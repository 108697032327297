import React from 'react';

const SvgLogo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 143.432 32.355"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.113 16.549A11.042 11.042 0 0 1 7.02 23.127c-.592 0-1.194-.047-1.786-.141a11.04 11.04 0 0 1-.798 10.525l6.822-6.343-.15-.197 2.875 3.608 5.77-5.91.038-.038.028.047 3.176 4.379a8.45 8.45 0 0 0 1.128-4.2 8.42 8.42 0 0 0-2.03-5.48 8.328 8.328 0 0 0-4.98-2.828z"
      fill="#fe5e39"
    />
    <path
      d="M5.093 27.375a11.178 11.178 0 0 1-2.885-7.508c0-5.676 4.23-10.375 9.698-11.136l.038-2.237C5.216 7.246 0 12.941 0 19.867c0 4.106 1.842 7.79 4.746 10.252.244-.892.357-1.823.347-2.744z"
      fill="#fe5e39"
    />
    <path
      d="M23.926 11.54a13.503 13.503 0 0 0-6.024-4.398l-.047-.018.02-.047c.77-1.72.168-3.346.018-3.703l-2.481 3.12-.028.028-.028-.019c-.32-.16-.846-.902-.846-4.06a2.687 2.687 0 0 1 2.687-2.687c.893 0 1.786.432 2.303 1.08a3.352 3.352 0 0 0-3.045-1.992 3.333 3.333 0 0 0-3.327 3.327l-.112 7.32v.02l-.01.009-3.928 4.962c1.231 1.597 2.528 1.954 2.932 1.964a8.458 8.458 0 0 1 4.473-1.269 8.44 8.44 0 0 1 3.731.865c3.017 1.39 5.122 4.435 5.122 7.978a9.02 9.02 0 0 1-.282 2.218c1.306-2.35 1.898-5.018 1.644-7.687a13.397 13.397 0 0 0-2.772-7.01zm-12.997 2.688 4.925-5.845s1.25-.32 3.317 1.287z"
      fill="#fe5e39"
    />
    <path
      d="M45.944 13.363c0-2.923-1.626-4.473-4.699-4.473h-6.597v14.462h6.597c3.083 0 4.7-1.55 4.7-4.473 0-1.203-.414-2.133-1.213-2.763.79-.62 1.212-1.54 1.212-2.753zm-3.646 5.638c0 .846-.338 1.194-1.165 1.194h-3.017v-8.157h3.017c.827 0 1.165.348 1.165 1.193 0 .752-.226 1.918-1.983 1.918v1.926c1.757.01 1.983 1.184 1.983 1.926zM56.967 17.62c0 1.87-.883 2.735-2.8 2.735-1.917 0-2.8-.864-2.8-2.735V8.956H47.89v8.664c0 3.844 2.17 5.883 6.277 5.883s6.277-2.04 6.277-5.883V8.956h-3.477zM69.25 14.52h-2.115c-.949 0-1.146-.405-1.146-1.025 0-.366 0-1.475 2.894-1.475 1.438 0 2.82.244 4.107.723l.667.245V9.66l-.338-.113c-1.4-.46-2.895-.686-4.436-.686-5.272 0-6.371 2.519-6.371 4.624 0 1.945.798 4.266 4.623 4.266h2.115c1.24 0 1.41.489 1.41 1.006 0 .432 0 1.578-2.613 1.578-1.617 0-3.101-.244-4.398-.733l-.667-.244v3.327l.347.113c1.41.46 2.998.686 4.718.686 5.027 0 6.08-2.575 6.08-4.727 0-1.927-.846-4.238-4.877-4.238zM108.285 17.63c0 1.85-.892 2.715-2.8 2.715s-2.8-.864-2.8-2.716V8.965h-3.477v8.664c0 3.844 2.17 5.874 6.286 5.874 4.107 0 6.278-2.03 6.278-5.874V8.965h-3.477v8.664zM121.8 8.872c-5.132 0-7.745 2.396-7.745 7.132 0 4.971 2.603 7.5 7.744 7.5 1.531 0 2.922-.236 4.125-.696l.32-.113V19.35l-.686.3a8.977 8.977 0 0 1-3.57.715c-2.97 0-4.352-1.382-4.352-4.351 0-2.716 1.381-3.975 4.351-3.975 1.278 0 2.48.234 3.571.714l.686.3V9.7l-.32-.123c-1.212-.47-2.594-.704-4.125-.704zM132.014 8.974h-3.486v14.444h3.486zM136.665 15.741l6.034-6.597-4.342-.047-5.159 5.723v1.992l5.46 6.607h4.774zM97.112 13.364c0-2.923-1.625-4.473-4.708-4.473h-6.597v5.525h-5.723V8.862h-3.477v14.444h3.477v-5.742h5.723v5.789h6.597c3.083 0 4.708-1.55 4.708-4.473 0-1.203-.413-2.133-1.212-2.763.799-.62 1.212-1.541 1.212-2.753zm-3.636 5.638c0 .846-.339 1.194-1.166 1.194h-3.016v-8.157h3.016c.827 0 1.166.347 1.166 1.193 0 .752-.226 1.917-1.993 1.917v1.927c1.767.009 1.993 1.184 1.993 1.926z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLogo;
