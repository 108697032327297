import {useCookie} from "../utils/useCookie";
const COOKIE_NAME = 'bushbuck_partners';
const QUERY_PARAM = 'partner';

export const setSessionPassword = passwordCandidate => {
    const [getCookie, removeCookie, updateCookie] = useCookie();
    updateCookie(COOKIE_NAME, passwordCandidate);
};

export const getSessionPassword = () => {
    const [getCookie] = useCookie();
    return getCookie(COOKIE_NAME);
};

export const getQueryPassword = location => {
    const params = new URLSearchParams(location.search);
    if (!params.has(QUERY_PARAM)) {
        return null;
    }
    return params.get(QUERY_PARAM);
};
