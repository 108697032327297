import { createSlice } from "@reduxjs/toolkit";
import { PRICE } from "../../components/productListing/constants";

const initialState = {
  filter: {
    availability: [],
    sizes: [],
    colors: [],
    price: {
      min: 0,
      max: 0,
    },
    vendors: [],
  },
  products: [],
  sortBy: "featured",
};

export const FilterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload ?? "featured";
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
    },
    updateFilter: (state, action) => {
      const { key, value } = action.payload;
      if (key === PRICE) {
        state.filter[key] = value;
      } else {
        if (state.filter[key].includes(value)) {
          state.filter[key] = state.filter[key].filter(
            (item) => item !== value
          );
        } else {
          state.filter[key].push(value);
        }
      }
    },
    removePrice: (state, action) => {
      state.filter.price = initialState.filter.price;
    },
  },
});

export const {
  setFilter,
  setProducts,
  setSortBy,
  resetFilter,
  updateFilter,
  removePrice,
} = FilterSlice.actions;

export default FilterSlice.reducer;
