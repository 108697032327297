import { createSlice } from "@reduxjs/toolkit";
import { LOADING, LOGGEDIN, LOGGEDOUT } from "../../constants/auth";
import { useCookie } from "./../../utils/useCookie";

const initialState = {
  authState: LOADING,
  user: null,
  shopper: "",
};
const COOKIE_NAME = "bushbuck_user";
const [getCookie, removeCookie, updateCookie] = useCookie(COOKIE_NAME);
export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      updateCookie(COOKIE_NAME, JSON.stringify(action.payload));
      state.authState = LOGGEDIN;
      state.user = {
        id: action.payload.id,
        email: action.payload.email,
        accessToken: action.payload.accessToken,
        expiredAt: action.payload.expiresAt,
      };
    },
    userLoggedOut: (state) => {
      removeCookie(COOKIE_NAME);
      state.authState = LOGGEDOUT;
      state.user = null;
    },

    setUser: (state, action) => {
      const cookie = getCookie(COOKIE_NAME);
      updateCookie(
        COOKIE_NAME,
        JSON.stringify({ ...cookie, ...action.payload })
      );
      state.user = { ...state.user, ...action.payload };
    },
    deleteAddress: (state, action) => {
      const cookie = getCookie(COOKIE_NAME);
      const newAddresses = cookie.addresses.nodes.filter(
        (address) => address.id !== action.payload
      );
      updateCookie(
        COOKIE_NAME,
        JSON.stringify({ ...cookie, addresses: { nodes: newAddresses } })
      );
      state.user = { ...state.user, addresses: { nodes: newAddresses } };
    },
    getUser: (state) => {
      const cookie = getCookie(COOKIE_NAME);
      if (cookie) {
        state.authState = LOGGEDIN;
        state.user = cookie;
      } else {
        state.authState = LOGGEDOUT;
        state.user = null;
      }
    },
    updateAddress: (state, action) => {
      const cookie = getCookie(COOKIE_NAME);
      const newAddresses = cookie.addresses.nodes.map((address) => {
        if (address.id === action.payload.id) {
          return action.payload;
        } else {
          return address;
        }
      });
      updateCookie(
        COOKIE_NAME,
        JSON.stringify({ ...cookie, addresses: { nodes: newAddresses } })
      );
      state.user = { ...state.user, addresses: { nodes: newAddresses } };
    },
    updateShopper: (state, action) => {
      updateCookie("shopper", JSON.stringify(action.payload));
      state.shopper = action.payload;
    },
  },
});

export const {
  userLoggedIn,
  userLoggedOut,
  setUser,
  getUser,
  deleteAddress,
  updateAddress,
  updateShopper,
} = authSlice.actions;

export default authSlice.reducer;
