import Cookies from "universal-cookie";

export const useCookie = (key, value) => {
  const cookies = new Cookies();
  const current = new Date();
  const nextDay = new Date();

  nextDay.setDate(current.getDate() + 1);
  const updateCookie = (key, value) => {
    let date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    const expires = new Date(date.toUTCString());
    cookies.set(key, value, { path: "/", expires: expires });
  };

  const getCookie = (key) => {
    return cookies.get(key);
  };

  const removeCookie = (key) => {
    return cookies.remove(key, {
      expires: "Thu, 01 Jan 1970 00:00:00 UTC",
      path: "/",
    });
  };
  return [getCookie, removeCookie, updateCookie];
};
