import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favorites: [],
};

const FAVORITES_KEY = "my_bushbuck_favorites";

export const WishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToFavorite: (state, action) => {
      const prevProducts = localStorage.getItem(FAVORITES_KEY);
      if (prevProducts) {
        state.favorites = [...JSON.parse(prevProducts), action.payload];
      } else {
        state.favorites = [action.payload];
      }

      localStorage.setItem(FAVORITES_KEY, JSON.stringify(state.favorites));
    },
    removeFromFavorites: (state, action) => {
      const prevProducts = localStorage.getItem(FAVORITES_KEY);
      if (prevProducts) {
        state.favorites = JSON.parse(prevProducts).filter(
          (product) => product.variant.shopifyId !== action.payload
        );
      } else {
        state.favorites = [];
      }

      localStorage.setItem(FAVORITES_KEY, JSON.stringify(state.favorites));
    },
    getFavorites: (state) => {
      const prevProducts = localStorage.getItem(FAVORITES_KEY);
      if (prevProducts) {
        state.favorites = JSON.parse(prevProducts);
      } else {
        state.favorites = [];
      }
    },
    clearFavorites: (state) => {
      state.favorites = [];
      localStorage.removeItem(FAVORITES_KEY);
    },
  },
});

export const {
  addToFavorite,
  removeFromFavorites,
  getFavorites,
  clearFavorites,
} = WishlistSlice.actions;

export default WishlistSlice.reducer;
